import * as Types from '../../../graphql/types';

import { CalendarMonthString, CalendarDateTimeString, CalendarDateString } from "scalars";
import { gql } from '@apollo/client';
import { PageInfoFragmentDoc } from '../../../graphql/fragments/pageInfo.generated';
import { MoneyFragmentDoc } from '../../../graphql/fragments/money.generated';
import { ReportsPageReportFragmentDoc } from '../../reports/graphql.generated';
import { MetricFragmentDoc } from '../../../graphql/fragments/metric.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AccrualRevenueAnalysisQueryVariables = Types.Exact<{
  companyId: Types.Scalars['ID'];
  input: Types.AccrualRevenueAnalysisInput;
}>;


export type AccrualRevenueAnalysisQuery = { __typename?: 'Query', company?: { __typename?: 'Company', id: string, accrualRevenueAnalysis: { __typename?: 'AccrualRevenueAnalysisResult', accrualRevenueAnalysis: { __typename?: 'AccrualRevenueAnalysis', totals: { __typename?: 'AccrualRevenueAnalysisMetrics', openingDeferredNet: { __typename?: 'Money', amount: string, currency: any }, revenueDeferred: { __typename?: 'Money', amount: string, currency: any }, revenueRecognized: { __typename?: 'Money', amount: string, currency: any }, closingDeferredNet: { __typename?: 'Money', amount: string, currency: any } }, periodMetrics?: Array<{ __typename?: 'AccrualRevenueAnalysisByPeriod', period: string, deferred: { __typename?: 'Money', amount: string, currency: any }, recognized: { __typename?: 'Money', amount: string, currency: any } }> | null } } } | null };

export type AccrualRevenueAnalysisByCustomerQueryVariables = Types.Exact<{
  companyId: Types.Scalars['ID'];
  input: Types.AccrualRevenueAnalysisByCustomerInput;
}>;


export type AccrualRevenueAnalysisByCustomerQuery = { __typename?: 'Query', company?: { __typename?: 'Company', id: string, accrualRevenueAnalysisByCustomer: { __typename?: 'AccrualRevenueAnalysisByCustomerResult', customers: Array<{ __typename?: 'Customer', id: string, name?: string | null, accrualRevenueAnalysis?: { __typename?: 'AccrualRevenueAnalysis', totals: { __typename?: 'AccrualRevenueAnalysisMetrics', openingDeferredNet: { __typename?: 'Money', amount: string, currency: any }, revenueDeferred: { __typename?: 'Money', amount: string, currency: any }, revenueRecognized: { __typename?: 'Money', amount: string, currency: any }, closingDeferredNet: { __typename?: 'Money', amount: string, currency: any } }, periodMetrics?: Array<{ __typename?: 'AccrualRevenueAnalysisByPeriod', period: string, deferred: { __typename?: 'Money', amount: string, currency: any }, recognized: { __typename?: 'Money', amount: string, currency: any } }> | null } | null }>, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, endCursor?: string | null, total?: number | null } } } | null };

export type AccrualRevenueAnalysisByCoaKeyQueryVariables = Types.Exact<{
  companyId: Types.Scalars['ID'];
  input: Types.AccrualRevenueAnalysisByCoaKeyInput;
}>;


export type AccrualRevenueAnalysisByCoaKeyQuery = { __typename?: 'Query', company?: { __typename?: 'Company', id: string, accrualRevenueAnalysisByCoaKey: { __typename?: 'AccrualRevenueAnalysisByCoaKeyResult', coaKeyMetrics: Array<{ __typename?: 'CoaKeyAccrualRevenueAnalysisMetric', coaKey: string, accrualRevenueAnalysis?: { __typename?: 'AccrualRevenueAnalysis', totals: { __typename?: 'AccrualRevenueAnalysisMetrics', openingDeferredNet: { __typename?: 'Money', amount: string, currency: any }, revenueDeferred: { __typename?: 'Money', amount: string, currency: any }, revenueRecognized: { __typename?: 'Money', amount: string, currency: any }, closingDeferredNet: { __typename?: 'Money', amount: string, currency: any } }, periodMetrics?: Array<{ __typename?: 'AccrualRevenueAnalysisByPeriod', period: string, deferred: { __typename?: 'Money', amount: string, currency: any }, recognized: { __typename?: 'Money', amount: string, currency: any } }> | null } | null }>, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, endCursor?: string | null, total?: number | null } } } | null };

export type AccrualRevenueAnalysisFragment = { __typename?: 'AccrualRevenueAnalysis', totals: { __typename?: 'AccrualRevenueAnalysisMetrics', openingDeferredNet: { __typename?: 'Money', amount: string, currency: any }, revenueDeferred: { __typename?: 'Money', amount: string, currency: any }, revenueRecognized: { __typename?: 'Money', amount: string, currency: any }, closingDeferredNet: { __typename?: 'Money', amount: string, currency: any } }, periodMetrics?: Array<{ __typename?: 'AccrualRevenueAnalysisByPeriod', period: string, deferred: { __typename?: 'Money', amount: string, currency: any }, recognized: { __typename?: 'Money', amount: string, currency: any } }> | null };

export type AccrualRevenueAnalysisMetricsFragment = { __typename?: 'AccrualRevenueAnalysisMetrics', openingDeferredNet: { __typename?: 'Money', amount: string, currency: any }, revenueDeferred: { __typename?: 'Money', amount: string, currency: any }, revenueRecognized: { __typename?: 'Money', amount: string, currency: any }, closingDeferredNet: { __typename?: 'Money', amount: string, currency: any } };

export type AccrualRevenueAnalysisByPeriodFragment = { __typename?: 'AccrualRevenueAnalysisByPeriod', period: string, deferred: { __typename?: 'Money', amount: string, currency: any }, recognized: { __typename?: 'Money', amount: string, currency: any } };

export type GetCompanyRevenueQueryVariables = Types.Exact<{
  companyId: Types.Scalars['ID'];
  input: Types.CompanyRevenueInput;
}>;


export type GetCompanyRevenueQuery = { __typename?: 'Query', company?: { __typename?: 'Company', id: string, hasRevenueTransactions: boolean, revenue: { __typename?: 'CompanyRevenuePaginator', periodsTotal: string, annualizedRevenue: string, items: Array<{ __typename?: 'RevenueItem', id: string, name: string, externalId: string, total: string, periods: Array<{ __typename?: 'RevenuePeriod', key: string, total: string }> }>, periods: Array<{ __typename?: 'RevenuePeriod', key: string, total: string }>, pageInfo: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean, total?: number | null } } } | null };

export type GetSchedulerFetchRevenueAllocationQueryVariables = Types.Exact<{
  companyId: Types.Scalars['ID'];
  entityId?: Types.InputMaybe<Types.Scalars['ID']>;
  startDate?: Types.InputMaybe<Types.Scalars['DateTime']>;
  endDate?: Types.InputMaybe<Types.Scalars['DateTime']>;
  groupBy?: Types.InputMaybe<Types.RevenueAllocationGroupBy>;
}>;


export type GetSchedulerFetchRevenueAllocationQuery = { __typename?: 'Query', schedulerFetchRevenueAllocation: { __typename?: 'SchedulerFetchRevenueAllocationResult', total: string, totalDeferred: string, totalRecognized: string, revenueAllocation: Array<{ __typename?: 'RevenueAllocation', aggregateName: string, aggregateId: string, total: string, totalRecognized: string, totalDeferred: string, normalizedPhases: Array<{ __typename?: 'RevenueAllocationNormalizedPhase', startDate: CalendarDateTimeString, endDate: CalendarDateTimeString, total: string }>, aggregate: Array<{ __typename?: 'RevenueAllocationAggregate', aggregateName: string, aggregateId: string, total: string, totalRecognized: string, totalDeferred: string, normalizedPhases: Array<{ __typename?: 'RevenueAllocationNormalizedPhase', startDate: CalendarDateTimeString, endDate: CalendarDateTimeString, total: string }> }> } | null> } };

export type GetRecurringRevenueMetricsQueryVariables = Types.Exact<{
  companyId: Types.Scalars['ID'];
  startMonth: Types.Scalars['Month'];
  endMonth: Types.Scalars['Month'];
  loadAllCustomers?: Types.InputMaybe<Types.Scalars['Boolean']>;
}>;


export type GetRecurringRevenueMetricsQuery = { __typename?: 'Query', getRecurringRevenueMetrics: { __typename?: 'RecurringRevenueMetricsResult', showAllMetrics?: boolean | null, metrics: Array<{ __typename?: 'RecurringRevenueMetrics', metricName: string, metricType: Types.RecurringRevenueMetricType, normalizedPhases: Array<{ __typename?: 'RecurringRevenueMetricNormalizedPhase', startDate: CalendarDateString, endDate: CalendarDateString, view: { __typename?: 'RecurringRevenueMetricView', mrr?: string | null, arr?: string | null, count?: string | null } }>, subaggregates?: Array<{ __typename?: 'RecurringRevenueMetricSubaggregate', aggregateName: string, externalAggregateId: string, aggregation: Types.RevenueAllocationGroupBy, normalizedPhases: Array<{ __typename?: 'RecurringRevenueMetricNormalizedPhase', startDate: CalendarDateString, endDate: CalendarDateString, view: { __typename?: 'RecurringRevenueMetricView', mrr?: string | null, arr?: string | null, count?: string | null } }> } | null> | null } | null> } };

export type AccrualRevenueReportQueryVariables = Types.Exact<{
  input: Types.AccrualRevenueReportInput;
}>;


export type AccrualRevenueReportQuery = { __typename?: 'Query', accrualRevenueReport: { __typename?: 'Report', reportId?: any | null, title: string, status: Types.ReportStatus, timePeriod?: { __typename?: 'ReportTimePeriod', timePeriodKey: string, start: CalendarDateString, end: CalendarDateString } | null, file?: { __typename?: 'File', id: string, filename?: string | null, contentType: string, size: number, isDeleted: boolean, downloadInfo: { __typename?: 'FileDownloadInfo', signedUrl: string, urlExpiresAt: CalendarDateTimeString } } | null } };

export type CompanyArrQueryVariables = Types.Exact<{
  input: Types.CompanyArrInput;
}>;


export type CompanyArrQuery = { __typename?: 'Query', companyArr: { __typename?: 'CompanyArr', id: any, status: Types.CompanyFinancialMetricsStatus, metrics?: { __typename?: 'CompanyArrMetrics', annualizedRevenue: { __typename?: 'Metric', amount: string, currency: any, dataFromRange: { __typename?: 'InclusiveDateRange', fromInclusive: CalendarDateString, toInclusive: CalendarDateString } }, stripeAnnualizedRevenue: { __typename?: 'Metric', amount: string, currency: any, dataFromRange: { __typename?: 'InclusiveDateRange', fromInclusive: CalendarDateString, toInclusive: CalendarDateString } }, annualizedAccrualRevenue: { __typename?: 'Metric', amount: string, currency: any, dataFromRange: { __typename?: 'InclusiveDateRange', fromInclusive: CalendarDateString, toInclusive: CalendarDateString } } } | null } };

export type SubscriptionCustomerReportQueryVariables = Types.Exact<{
  input: Types.SubscriptionCustomerReportInput;
}>;


export type SubscriptionCustomerReportQuery = { __typename?: 'Query', subscriptionCustomerReport: { __typename?: 'Report', reportId?: any | null, title: string, status: Types.ReportStatus, timePeriod?: { __typename?: 'ReportTimePeriod', timePeriodKey: string, start: CalendarDateString, end: CalendarDateString } | null, file?: { __typename?: 'File', id: string, filename?: string | null, contentType: string, size: number, isDeleted: boolean, downloadInfo: { __typename?: 'FileDownloadInfo', signedUrl: string, urlExpiresAt: CalendarDateTimeString } } | null } };

export type StripeTransactionReportCsvQueryVariables = Types.Exact<{
  input: Types.StripeTransactionReportInput;
}>;


export type StripeTransactionReportCsvQuery = { __typename?: 'Query', stripeTransactionReportCsv: { __typename?: 'AsyncReportResult', report: { __typename?: 'Report', reportId?: any | null, title: string, status: Types.ReportStatus, timePeriod?: { __typename?: 'ReportTimePeriod', timePeriodKey: string, start: CalendarDateString, end: CalendarDateString } | null, file?: { __typename?: 'File', id: string, filename?: string | null, contentType: string, size: number, isDeleted: boolean, downloadInfo: { __typename?: 'FileDownloadInfo', signedUrl: string, urlExpiresAt: CalendarDateTimeString } } | null } } };

export type RecurringRevenueMetricsResultFragment = { __typename?: 'RecurringRevenueMetricsResult', metrics: Array<{ __typename?: 'RecurringRevenueMetrics', metricName: string, metricType: Types.RecurringRevenueMetricType, normalizedPhases: Array<{ __typename?: 'RecurringRevenueMetricNormalizedPhase', startDate: CalendarDateString, endDate: CalendarDateString, view: { __typename?: 'RecurringRevenueMetricView', mrr?: string | null, arr?: string | null, count?: string | null } }>, subaggregates?: Array<{ __typename?: 'RecurringRevenueMetricSubaggregate', aggregateName: string, externalAggregateId: string, aggregation: Types.RevenueAllocationGroupBy, normalizedPhases: Array<{ __typename?: 'RecurringRevenueMetricNormalizedPhase', startDate: CalendarDateString, endDate: CalendarDateString, view: { __typename?: 'RecurringRevenueMetricView', mrr?: string | null, arr?: string | null, count?: string | null } }> } | null> | null } | null> };

export type RecurringRevenueMetricsFragment = { __typename?: 'RecurringRevenueMetrics', metricName: string, metricType: Types.RecurringRevenueMetricType, normalizedPhases: Array<{ __typename?: 'RecurringRevenueMetricNormalizedPhase', startDate: CalendarDateString, endDate: CalendarDateString, view: { __typename?: 'RecurringRevenueMetricView', mrr?: string | null, arr?: string | null, count?: string | null } }>, subaggregates?: Array<{ __typename?: 'RecurringRevenueMetricSubaggregate', aggregateName: string, externalAggregateId: string, aggregation: Types.RevenueAllocationGroupBy, normalizedPhases: Array<{ __typename?: 'RecurringRevenueMetricNormalizedPhase', startDate: CalendarDateString, endDate: CalendarDateString, view: { __typename?: 'RecurringRevenueMetricView', mrr?: string | null, arr?: string | null, count?: string | null } }> } | null> | null };

export type RecurringRevenueMetricSubaggregateFragment = { __typename?: 'RecurringRevenueMetricSubaggregate', aggregateName: string, externalAggregateId: string, aggregation: Types.RevenueAllocationGroupBy, normalizedPhases: Array<{ __typename?: 'RecurringRevenueMetricNormalizedPhase', startDate: CalendarDateString, endDate: CalendarDateString, view: { __typename?: 'RecurringRevenueMetricView', mrr?: string | null, arr?: string | null, count?: string | null } }> };

export type RecurringRevenueMetricNormalizedPhaseFragment = { __typename?: 'RecurringRevenueMetricNormalizedPhase', startDate: CalendarDateString, endDate: CalendarDateString, view: { __typename?: 'RecurringRevenueMetricView', mrr?: string | null, arr?: string | null, count?: string | null } };

export type RevenuePeriodFragment = { __typename?: 'RevenuePeriod', key: string, total: string };

export type RevenueItemFragment = { __typename?: 'RevenueItem', id: string, name: string, externalId: string, total: string, periods: Array<{ __typename?: 'RevenuePeriod', key: string, total: string }> };

export type SchedulerFetchRevenueAllocationResultFragment = { __typename?: 'SchedulerFetchRevenueAllocationResult', total: string, totalDeferred: string, totalRecognized: string, revenueAllocation: Array<{ __typename?: 'RevenueAllocation', aggregateName: string, aggregateId: string, total: string, totalRecognized: string, totalDeferred: string, normalizedPhases: Array<{ __typename?: 'RevenueAllocationNormalizedPhase', startDate: CalendarDateTimeString, endDate: CalendarDateTimeString, total: string }>, aggregate: Array<{ __typename?: 'RevenueAllocationAggregate', aggregateName: string, aggregateId: string, total: string, totalRecognized: string, totalDeferred: string, normalizedPhases: Array<{ __typename?: 'RevenueAllocationNormalizedPhase', startDate: CalendarDateTimeString, endDate: CalendarDateTimeString, total: string }> }> } | null> };

export type RevenueAllocationFragment = { __typename?: 'RevenueAllocation', aggregateName: string, aggregateId: string, total: string, totalRecognized: string, totalDeferred: string, normalizedPhases: Array<{ __typename?: 'RevenueAllocationNormalizedPhase', startDate: CalendarDateTimeString, endDate: CalendarDateTimeString, total: string }>, aggregate: Array<{ __typename?: 'RevenueAllocationAggregate', aggregateName: string, aggregateId: string, total: string, totalRecognized: string, totalDeferred: string, normalizedPhases: Array<{ __typename?: 'RevenueAllocationNormalizedPhase', startDate: CalendarDateTimeString, endDate: CalendarDateTimeString, total: string }> }> };

export type RevenueAllocationAggregateFragment = { __typename?: 'RevenueAllocationAggregate', aggregateName: string, aggregateId: string, total: string, totalRecognized: string, totalDeferred: string, normalizedPhases: Array<{ __typename?: 'RevenueAllocationNormalizedPhase', startDate: CalendarDateTimeString, endDate: CalendarDateTimeString, total: string }> };

export type RevenueAllocationNormalizedPhaseFragment = { __typename?: 'RevenueAllocationNormalizedPhase', startDate: CalendarDateTimeString, endDate: CalendarDateTimeString, total: string };

export const AccrualRevenueAnalysisMetricsFragmentDoc = gql`
    fragment accrualRevenueAnalysisMetrics on AccrualRevenueAnalysisMetrics {
  openingDeferredNet {
    ...money
  }
  revenueDeferred {
    ...money
  }
  revenueRecognized {
    ...money
  }
  closingDeferredNet {
    ...money
  }
}
    ${MoneyFragmentDoc}`;
export const AccrualRevenueAnalysisByPeriodFragmentDoc = gql`
    fragment accrualRevenueAnalysisByPeriod on AccrualRevenueAnalysisByPeriod {
  period
  deferred {
    ...money
  }
  recognized {
    ...money
  }
}
    ${MoneyFragmentDoc}`;
export const AccrualRevenueAnalysisFragmentDoc = gql`
    fragment accrualRevenueAnalysis on AccrualRevenueAnalysis {
  totals {
    ...accrualRevenueAnalysisMetrics
  }
  periodMetrics {
    ...accrualRevenueAnalysisByPeriod
  }
}
    ${AccrualRevenueAnalysisMetricsFragmentDoc}
${AccrualRevenueAnalysisByPeriodFragmentDoc}`;
export const RecurringRevenueMetricNormalizedPhaseFragmentDoc = gql`
    fragment recurringRevenueMetricNormalizedPhase on RecurringRevenueMetricNormalizedPhase {
  startDate
  endDate
  view {
    mrr
    arr
    count
  }
}
    `;
export const RecurringRevenueMetricSubaggregateFragmentDoc = gql`
    fragment recurringRevenueMetricSubaggregate on RecurringRevenueMetricSubaggregate {
  aggregateName
  externalAggregateId
  aggregation
  normalizedPhases {
    ...recurringRevenueMetricNormalizedPhase
  }
}
    ${RecurringRevenueMetricNormalizedPhaseFragmentDoc}`;
export const RecurringRevenueMetricsFragmentDoc = gql`
    fragment recurringRevenueMetrics on RecurringRevenueMetrics {
  metricName
  metricType
  normalizedPhases {
    ...recurringRevenueMetricNormalizedPhase
  }
  subaggregates {
    ...recurringRevenueMetricSubaggregate
  }
}
    ${RecurringRevenueMetricNormalizedPhaseFragmentDoc}
${RecurringRevenueMetricSubaggregateFragmentDoc}`;
export const RecurringRevenueMetricsResultFragmentDoc = gql`
    fragment recurringRevenueMetricsResult on RecurringRevenueMetricsResult {
  metrics {
    ...recurringRevenueMetrics
  }
}
    ${RecurringRevenueMetricsFragmentDoc}`;
export const RevenuePeriodFragmentDoc = gql`
    fragment revenuePeriod on RevenuePeriod {
  key
  total
}
    `;
export const RevenueItemFragmentDoc = gql`
    fragment revenueItem on RevenueItem {
  id
  name
  externalId
  total
  periods {
    key
    total
  }
}
    `;
export const RevenueAllocationNormalizedPhaseFragmentDoc = gql`
    fragment revenueAllocationNormalizedPhase on RevenueAllocationNormalizedPhase {
  startDate
  endDate
  total
}
    `;
export const RevenueAllocationAggregateFragmentDoc = gql`
    fragment revenueAllocationAggregate on RevenueAllocationAggregate {
  aggregateName
  aggregateId
  total
  totalRecognized
  totalDeferred
  normalizedPhases {
    ...revenueAllocationNormalizedPhase
  }
}
    ${RevenueAllocationNormalizedPhaseFragmentDoc}`;
export const RevenueAllocationFragmentDoc = gql`
    fragment revenueAllocation on RevenueAllocation {
  aggregateName
  aggregateId
  total
  totalRecognized
  totalDeferred
  normalizedPhases {
    ...revenueAllocationNormalizedPhase
  }
  aggregate {
    ...revenueAllocationAggregate
  }
}
    ${RevenueAllocationNormalizedPhaseFragmentDoc}
${RevenueAllocationAggregateFragmentDoc}`;
export const SchedulerFetchRevenueAllocationResultFragmentDoc = gql`
    fragment schedulerFetchRevenueAllocationResult on SchedulerFetchRevenueAllocationResult {
  total
  totalDeferred
  totalRecognized
  revenueAllocation {
    ...revenueAllocation
  }
}
    ${RevenueAllocationFragmentDoc}`;
export const AccrualRevenueAnalysisDocument = gql`
    query accrualRevenueAnalysis($companyId: ID!, $input: AccrualRevenueAnalysisInput!) {
  company(id: $companyId) {
    id
    accrualRevenueAnalysis(input: $input) {
      accrualRevenueAnalysis {
        ...accrualRevenueAnalysis
      }
    }
  }
}
    ${AccrualRevenueAnalysisFragmentDoc}`;

/**
 * __useAccrualRevenueAnalysisQuery__
 *
 * To run a query within a React component, call `useAccrualRevenueAnalysisQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccrualRevenueAnalysisQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccrualRevenueAnalysisQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAccrualRevenueAnalysisQuery(baseOptions: Apollo.QueryHookOptions<AccrualRevenueAnalysisQuery, AccrualRevenueAnalysisQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccrualRevenueAnalysisQuery, AccrualRevenueAnalysisQueryVariables>(AccrualRevenueAnalysisDocument, options);
      }
export function useAccrualRevenueAnalysisLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccrualRevenueAnalysisQuery, AccrualRevenueAnalysisQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccrualRevenueAnalysisQuery, AccrualRevenueAnalysisQueryVariables>(AccrualRevenueAnalysisDocument, options);
        }
export type AccrualRevenueAnalysisQueryHookResult = ReturnType<typeof useAccrualRevenueAnalysisQuery>;
export type AccrualRevenueAnalysisLazyQueryHookResult = ReturnType<typeof useAccrualRevenueAnalysisLazyQuery>;
export type AccrualRevenueAnalysisQueryResult = Apollo.QueryResult<AccrualRevenueAnalysisQuery, AccrualRevenueAnalysisQueryVariables>;
export const AccrualRevenueAnalysisByCustomerDocument = gql`
    query accrualRevenueAnalysisByCustomer($companyId: ID!, $input: AccrualRevenueAnalysisByCustomerInput!) {
  company(id: $companyId) {
    id
    accrualRevenueAnalysisByCustomer(input: $input) {
      customers {
        id
        name
        accrualRevenueAnalysis {
          ...accrualRevenueAnalysis
        }
      }
      pageInfo {
        ...pageInfo
      }
    }
  }
}
    ${AccrualRevenueAnalysisFragmentDoc}
${PageInfoFragmentDoc}`;

/**
 * __useAccrualRevenueAnalysisByCustomerQuery__
 *
 * To run a query within a React component, call `useAccrualRevenueAnalysisByCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccrualRevenueAnalysisByCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccrualRevenueAnalysisByCustomerQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAccrualRevenueAnalysisByCustomerQuery(baseOptions: Apollo.QueryHookOptions<AccrualRevenueAnalysisByCustomerQuery, AccrualRevenueAnalysisByCustomerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccrualRevenueAnalysisByCustomerQuery, AccrualRevenueAnalysisByCustomerQueryVariables>(AccrualRevenueAnalysisByCustomerDocument, options);
      }
export function useAccrualRevenueAnalysisByCustomerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccrualRevenueAnalysisByCustomerQuery, AccrualRevenueAnalysisByCustomerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccrualRevenueAnalysisByCustomerQuery, AccrualRevenueAnalysisByCustomerQueryVariables>(AccrualRevenueAnalysisByCustomerDocument, options);
        }
export type AccrualRevenueAnalysisByCustomerQueryHookResult = ReturnType<typeof useAccrualRevenueAnalysisByCustomerQuery>;
export type AccrualRevenueAnalysisByCustomerLazyQueryHookResult = ReturnType<typeof useAccrualRevenueAnalysisByCustomerLazyQuery>;
export type AccrualRevenueAnalysisByCustomerQueryResult = Apollo.QueryResult<AccrualRevenueAnalysisByCustomerQuery, AccrualRevenueAnalysisByCustomerQueryVariables>;
export const AccrualRevenueAnalysisByCoaKeyDocument = gql`
    query accrualRevenueAnalysisByCoaKey($companyId: ID!, $input: AccrualRevenueAnalysisByCoaKeyInput!) {
  company(id: $companyId) {
    id
    accrualRevenueAnalysisByCoaKey(input: $input) {
      coaKeyMetrics {
        coaKey
        accrualRevenueAnalysis {
          ...accrualRevenueAnalysis
        }
      }
      pageInfo {
        ...pageInfo
      }
    }
  }
}
    ${AccrualRevenueAnalysisFragmentDoc}
${PageInfoFragmentDoc}`;

/**
 * __useAccrualRevenueAnalysisByCoaKeyQuery__
 *
 * To run a query within a React component, call `useAccrualRevenueAnalysisByCoaKeyQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccrualRevenueAnalysisByCoaKeyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccrualRevenueAnalysisByCoaKeyQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAccrualRevenueAnalysisByCoaKeyQuery(baseOptions: Apollo.QueryHookOptions<AccrualRevenueAnalysisByCoaKeyQuery, AccrualRevenueAnalysisByCoaKeyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccrualRevenueAnalysisByCoaKeyQuery, AccrualRevenueAnalysisByCoaKeyQueryVariables>(AccrualRevenueAnalysisByCoaKeyDocument, options);
      }
export function useAccrualRevenueAnalysisByCoaKeyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccrualRevenueAnalysisByCoaKeyQuery, AccrualRevenueAnalysisByCoaKeyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccrualRevenueAnalysisByCoaKeyQuery, AccrualRevenueAnalysisByCoaKeyQueryVariables>(AccrualRevenueAnalysisByCoaKeyDocument, options);
        }
export type AccrualRevenueAnalysisByCoaKeyQueryHookResult = ReturnType<typeof useAccrualRevenueAnalysisByCoaKeyQuery>;
export type AccrualRevenueAnalysisByCoaKeyLazyQueryHookResult = ReturnType<typeof useAccrualRevenueAnalysisByCoaKeyLazyQuery>;
export type AccrualRevenueAnalysisByCoaKeyQueryResult = Apollo.QueryResult<AccrualRevenueAnalysisByCoaKeyQuery, AccrualRevenueAnalysisByCoaKeyQueryVariables>;
export const GetCompanyRevenueDocument = gql`
    query getCompanyRevenue($companyId: ID!, $input: CompanyRevenueInput!) {
  company(id: $companyId) {
    id
    hasRevenueTransactions
    revenue(input: $input) {
      items {
        ...revenueItem
      }
      periods {
        ...revenuePeriod
      }
      pageInfo {
        endCursor
        hasNextPage
        total
      }
      periodsTotal
      annualizedRevenue
    }
  }
}
    ${RevenueItemFragmentDoc}
${RevenuePeriodFragmentDoc}`;

/**
 * __useGetCompanyRevenueQuery__
 *
 * To run a query within a React component, call `useGetCompanyRevenueQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyRevenueQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyRevenueQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetCompanyRevenueQuery(baseOptions: Apollo.QueryHookOptions<GetCompanyRevenueQuery, GetCompanyRevenueQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCompanyRevenueQuery, GetCompanyRevenueQueryVariables>(GetCompanyRevenueDocument, options);
      }
export function useGetCompanyRevenueLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompanyRevenueQuery, GetCompanyRevenueQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCompanyRevenueQuery, GetCompanyRevenueQueryVariables>(GetCompanyRevenueDocument, options);
        }
export type GetCompanyRevenueQueryHookResult = ReturnType<typeof useGetCompanyRevenueQuery>;
export type GetCompanyRevenueLazyQueryHookResult = ReturnType<typeof useGetCompanyRevenueLazyQuery>;
export type GetCompanyRevenueQueryResult = Apollo.QueryResult<GetCompanyRevenueQuery, GetCompanyRevenueQueryVariables>;
export const GetSchedulerFetchRevenueAllocationDocument = gql`
    query getSchedulerFetchRevenueAllocation($companyId: ID!, $entityId: ID, $startDate: DateTime, $endDate: DateTime, $groupBy: RevenueAllocationGroupBy) {
  schedulerFetchRevenueAllocation(
    companyId: $companyId
    entityId: $entityId
    startDate: $startDate
    endDate: $endDate
    groupBy: $groupBy
  ) {
    ...schedulerFetchRevenueAllocationResult
  }
}
    ${SchedulerFetchRevenueAllocationResultFragmentDoc}`;

/**
 * __useGetSchedulerFetchRevenueAllocationQuery__
 *
 * To run a query within a React component, call `useGetSchedulerFetchRevenueAllocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSchedulerFetchRevenueAllocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSchedulerFetchRevenueAllocationQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      entityId: // value for 'entityId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      groupBy: // value for 'groupBy'
 *   },
 * });
 */
export function useGetSchedulerFetchRevenueAllocationQuery(baseOptions: Apollo.QueryHookOptions<GetSchedulerFetchRevenueAllocationQuery, GetSchedulerFetchRevenueAllocationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSchedulerFetchRevenueAllocationQuery, GetSchedulerFetchRevenueAllocationQueryVariables>(GetSchedulerFetchRevenueAllocationDocument, options);
      }
export function useGetSchedulerFetchRevenueAllocationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSchedulerFetchRevenueAllocationQuery, GetSchedulerFetchRevenueAllocationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSchedulerFetchRevenueAllocationQuery, GetSchedulerFetchRevenueAllocationQueryVariables>(GetSchedulerFetchRevenueAllocationDocument, options);
        }
export type GetSchedulerFetchRevenueAllocationQueryHookResult = ReturnType<typeof useGetSchedulerFetchRevenueAllocationQuery>;
export type GetSchedulerFetchRevenueAllocationLazyQueryHookResult = ReturnType<typeof useGetSchedulerFetchRevenueAllocationLazyQuery>;
export type GetSchedulerFetchRevenueAllocationQueryResult = Apollo.QueryResult<GetSchedulerFetchRevenueAllocationQuery, GetSchedulerFetchRevenueAllocationQueryVariables>;
export const GetRecurringRevenueMetricsDocument = gql`
    query getRecurringRevenueMetrics($companyId: ID!, $startMonth: Month!, $endMonth: Month!, $loadAllCustomers: Boolean) {
  getRecurringRevenueMetrics(
    companyId: $companyId
    startMonth: $startMonth
    endMonth: $endMonth
    loadAllCustomers: $loadAllCustomers
  ) {
    ...recurringRevenueMetricsResult
    showAllMetrics
  }
}
    ${RecurringRevenueMetricsResultFragmentDoc}`;

/**
 * __useGetRecurringRevenueMetricsQuery__
 *
 * To run a query within a React component, call `useGetRecurringRevenueMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecurringRevenueMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecurringRevenueMetricsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      startMonth: // value for 'startMonth'
 *      endMonth: // value for 'endMonth'
 *      loadAllCustomers: // value for 'loadAllCustomers'
 *   },
 * });
 */
export function useGetRecurringRevenueMetricsQuery(baseOptions: Apollo.QueryHookOptions<GetRecurringRevenueMetricsQuery, GetRecurringRevenueMetricsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRecurringRevenueMetricsQuery, GetRecurringRevenueMetricsQueryVariables>(GetRecurringRevenueMetricsDocument, options);
      }
export function useGetRecurringRevenueMetricsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRecurringRevenueMetricsQuery, GetRecurringRevenueMetricsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRecurringRevenueMetricsQuery, GetRecurringRevenueMetricsQueryVariables>(GetRecurringRevenueMetricsDocument, options);
        }
export type GetRecurringRevenueMetricsQueryHookResult = ReturnType<typeof useGetRecurringRevenueMetricsQuery>;
export type GetRecurringRevenueMetricsLazyQueryHookResult = ReturnType<typeof useGetRecurringRevenueMetricsLazyQuery>;
export type GetRecurringRevenueMetricsQueryResult = Apollo.QueryResult<GetRecurringRevenueMetricsQuery, GetRecurringRevenueMetricsQueryVariables>;
export const AccrualRevenueReportDocument = gql`
    query accrualRevenueReport($input: AccrualRevenueReportInput!) {
  accrualRevenueReport(input: $input) {
    ...reportsPageReport
  }
}
    ${ReportsPageReportFragmentDoc}`;

/**
 * __useAccrualRevenueReportQuery__
 *
 * To run a query within a React component, call `useAccrualRevenueReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccrualRevenueReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccrualRevenueReportQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAccrualRevenueReportQuery(baseOptions: Apollo.QueryHookOptions<AccrualRevenueReportQuery, AccrualRevenueReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccrualRevenueReportQuery, AccrualRevenueReportQueryVariables>(AccrualRevenueReportDocument, options);
      }
export function useAccrualRevenueReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccrualRevenueReportQuery, AccrualRevenueReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccrualRevenueReportQuery, AccrualRevenueReportQueryVariables>(AccrualRevenueReportDocument, options);
        }
export type AccrualRevenueReportQueryHookResult = ReturnType<typeof useAccrualRevenueReportQuery>;
export type AccrualRevenueReportLazyQueryHookResult = ReturnType<typeof useAccrualRevenueReportLazyQuery>;
export type AccrualRevenueReportQueryResult = Apollo.QueryResult<AccrualRevenueReportQuery, AccrualRevenueReportQueryVariables>;
export const CompanyArrDocument = gql`
    query companyArr($input: CompanyArrInput!) {
  companyArr(input: $input) {
    id
    status
    metrics {
      annualizedRevenue {
        ...metric
      }
      stripeAnnualizedRevenue {
        ...metric
      }
      annualizedAccrualRevenue {
        ...metric
      }
    }
  }
}
    ${MetricFragmentDoc}`;

/**
 * __useCompanyArrQuery__
 *
 * To run a query within a React component, call `useCompanyArrQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyArrQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyArrQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompanyArrQuery(baseOptions: Apollo.QueryHookOptions<CompanyArrQuery, CompanyArrQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompanyArrQuery, CompanyArrQueryVariables>(CompanyArrDocument, options);
      }
export function useCompanyArrLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompanyArrQuery, CompanyArrQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompanyArrQuery, CompanyArrQueryVariables>(CompanyArrDocument, options);
        }
export type CompanyArrQueryHookResult = ReturnType<typeof useCompanyArrQuery>;
export type CompanyArrLazyQueryHookResult = ReturnType<typeof useCompanyArrLazyQuery>;
export type CompanyArrQueryResult = Apollo.QueryResult<CompanyArrQuery, CompanyArrQueryVariables>;
export const SubscriptionCustomerReportDocument = gql`
    query subscriptionCustomerReport($input: SubscriptionCustomerReportInput!) {
  subscriptionCustomerReport(input: $input) {
    ...reportsPageReport
  }
}
    ${ReportsPageReportFragmentDoc}`;

/**
 * __useSubscriptionCustomerReportQuery__
 *
 * To run a query within a React component, call `useSubscriptionCustomerReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubscriptionCustomerReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscriptionCustomerReportQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubscriptionCustomerReportQuery(baseOptions: Apollo.QueryHookOptions<SubscriptionCustomerReportQuery, SubscriptionCustomerReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SubscriptionCustomerReportQuery, SubscriptionCustomerReportQueryVariables>(SubscriptionCustomerReportDocument, options);
      }
export function useSubscriptionCustomerReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SubscriptionCustomerReportQuery, SubscriptionCustomerReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SubscriptionCustomerReportQuery, SubscriptionCustomerReportQueryVariables>(SubscriptionCustomerReportDocument, options);
        }
export type SubscriptionCustomerReportQueryHookResult = ReturnType<typeof useSubscriptionCustomerReportQuery>;
export type SubscriptionCustomerReportLazyQueryHookResult = ReturnType<typeof useSubscriptionCustomerReportLazyQuery>;
export type SubscriptionCustomerReportQueryResult = Apollo.QueryResult<SubscriptionCustomerReportQuery, SubscriptionCustomerReportQueryVariables>;
export const StripeTransactionReportCsvDocument = gql`
    query stripeTransactionReportCsv($input: StripeTransactionReportInput!) {
  stripeTransactionReportCsv(input: $input) {
    report {
      ...reportsPageReport
    }
  }
}
    ${ReportsPageReportFragmentDoc}`;

/**
 * __useStripeTransactionReportCsvQuery__
 *
 * To run a query within a React component, call `useStripeTransactionReportCsvQuery` and pass it any options that fit your needs.
 * When your component renders, `useStripeTransactionReportCsvQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStripeTransactionReportCsvQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStripeTransactionReportCsvQuery(baseOptions: Apollo.QueryHookOptions<StripeTransactionReportCsvQuery, StripeTransactionReportCsvQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StripeTransactionReportCsvQuery, StripeTransactionReportCsvQueryVariables>(StripeTransactionReportCsvDocument, options);
      }
export function useStripeTransactionReportCsvLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StripeTransactionReportCsvQuery, StripeTransactionReportCsvQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StripeTransactionReportCsvQuery, StripeTransactionReportCsvQueryVariables>(StripeTransactionReportCsvDocument, options);
        }
export type StripeTransactionReportCsvQueryHookResult = ReturnType<typeof useStripeTransactionReportCsvQuery>;
export type StripeTransactionReportCsvLazyQueryHookResult = ReturnType<typeof useStripeTransactionReportCsvLazyQuery>;
export type StripeTransactionReportCsvQueryResult = Apollo.QueryResult<StripeTransactionReportCsvQuery, StripeTransactionReportCsvQueryVariables>;