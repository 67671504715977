import * as Types from '../types';

import { CalendarMonthString, CalendarDateTimeString, CalendarDateString } from "scalars";
import { gql } from '@apollo/client';
export type MetricFragment = { __typename?: 'Metric', amount: string, currency: any, dataFromRange: { __typename?: 'InclusiveDateRange', fromInclusive: CalendarDateString, toInclusive: CalendarDateString } };

export const MetricFragmentDoc = gql`
    fragment metric on Metric {
  amount
  currency
  dataFromRange {
    fromInclusive
    toInclusive
  }
}
    `;